<template>
  <b-card
    v-if="Object.keys(showData).length"
    class="blog-edit-wrapper"
  >
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Title"
            label-for="blog-edit-title"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-title"
              v-model="showData.activity_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Status"
            label-for="blog-edit-category"
            class="mb-2"
          >
            <v-select
              id="blog-edit-category"
              v-model="showData.enable_status"
              :options="statusOption"
              :reduce="tag => tag.id"
              label="name"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col md="6">
          <b-form-group>
            <h5>Start Time</h5>
            <flat-pickr
              v-model="addAnnounce.startDate"
              class="form-control"
              :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
            />
          </b-form-group>
        </b-col> -->
        <!-- <b-col md="6">
          <b-form-group>
            <h5>End Time</h5>
            <flat-pickr
              v-model="addAnnounce.endDate"
              class="form-control"
              :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
            />
          </b-form-group>
        </b-col> -->
        <b-col cols="12">
          <b-form-group
            label="Content"
            label-for="blog-content"
            class="mb-2"
          >
            <quill-editor
              id="blog-content"
              v-model="showData.activity_description"
              :options="snowOption"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          <div class="border rounded p-2">
            <h4 class="mb-1">
              Featured Image
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                  ref="refPreviewEl"
                  :src="showData.media_file"
                  height="150"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted">Required image resolution 800x400, image size 10mb.</small>
                <b-card-text class="my-50">
                  <b-link id="blog-image-text">
                    C:\fakepath\{{ showData.media_file ? showData.media_file.name : 'banner.jpg' }}
                  </b-link>
                </b-card-text>
                <div class="d-inline-block">
                  <b-form-file
                    ref="refInputEl"
                    accept=".jpg, .png, .gif"
                    placeholder="Choose file"
                    @input="inputImageRenderer"
                    @change="onFileSelected"
                  />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-col>
        <div class="mr-2 ml-3">
          <b-form-group
            label="Comments"
            label-for="blog-edit-category"
            class="mb-2"
          >
            <b-form-checkbox
              v-model="enable"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="BellIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="BellOffIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </div>
        <div class="mr-2">
          <b-form-group
            label="Likes"
            label-for="blog-edit-category"
            class="mb-2"
          >
            <b-form-checkbox
              :v-model="resolveGenStatusValue(showData.enable_likes)"
              class="custom-control-primary"
              name="check-button"
              value="likes"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="BellIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="BellOffIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </div>
        <div>
          <b-form-group
            label="Share"
            label-for="blog-edit-category"
            class="mb-2"
          >
            <b-form-checkbox
              v-model="showData.enable_share"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="BellIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="BellOffIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </div>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="updateAnn"
          >
            Save
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import axios from 'axios'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import router from '@/router'
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, onUnmounted, reactive, computed, watchEffect,
} from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import { registerPostUpdate } from 'echarts/lib/echarts'
import announcements from '../announcements'
import announceDataModule from '../announementsModule'

export default {
  components: {
    BCard,
    BMedia,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    quillEditor,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      blogFile: null,
      categoryOption: ['First', 'Second'],
      statusOption: [
        {
          id: 1,
          name: 'Active',
        },
        {
          id: 0,
          name: 'Not Active',
        },
      ],
      snowOption: {
        theme: 'snow',
      },
      comments: [
        { text: 'Yes', value: 'orange' },
        { text: 'NO', value: 'apple' },
      ],
      likes: null,
    }
  },
  created() {
    this.enable()
  },
  mounted() {
    this.enable()
  },
  setup() {
    const ANNOUNCE_MODULE = 'announceData'
    // Register module
    if (!store.hasModule(ANNOUNCE_MODULE)) store.registerModule(ANNOUNCE_MODULE, announceDataModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ANNOUNCE_MODULE)) store.unregisterModule(ANNOUNCE_MODULE)
    })

    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    const {
      addAnnounce, update, onFileSelected, selectedFile, showData, resolveGenStatusValue,
    } = announcements()

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
      addAnnounce,
      update,
      selectedFile,
      onFileSelected,
      showData,
      resolveGenStatusValue,
    }
  },
  methods: {
    enable() {
      return this.resolveGenStatusValue(this.showData.enable_likes)
    },
    updateComm() {
      store.dispatch('announceData/updateAnnounce', { id: router.currentRoute.params.id }, { ...this.showData }).then(res => {
        console.log(res.data)
      }).catch(err => console.log(err))
    },
    updateAnn() {
      axios.post(`https://activities.muslimdo.com/api/internalops/activities/${router.currentRoute.params.id}?_method=PUT`, { ...this.showData }).then(res => {
        console.log(res)
        if (res.data.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Udpated Successfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          router.push('/announcecments')
          // eslint-disable-next-line no-empty
        } else {}
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
